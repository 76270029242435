import React from 'react';
import './App.css';
import Appbar from "./components/Appbar";
import Iban from "./components/Iban";

function App() {
    return (
        <div className="App">
            <Appbar/>
            <Iban/>
        </div>
    );
}

export default App;
