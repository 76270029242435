import React, {useCallback, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {Container, Paper, Button} from "@material-ui/core";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const useStyles = makeStyles(theme => ({
    root: {
        "& > *": {
            margin: theme.spacing(1)
        }
    }
}));

/**
 * Main IBAN UI component, used to submit IBAN address verification and to list all previously submitted IBAN addresses.
 * @constructor
 */
export default function Iban() {
    const paperStyle = {padding: "50px 20px", width: 600, margin: "20px auto"};
    const [currentSort, setSort] = useState("sort");
    const [currentSortIndex, setSortIndex] = useState(0);
    const sortTypes = ["sort", "sort-up", "sort-down"]

    const mountedRef = useRef(true);
    const [iban, setIban] = useState("");
    const [ibans, setIbans] = useState([]);
    const classes = useStyles();

    /**
     * Update sort order state on frontend.
     * Request new sort order from backend.
     */
    const onSortChange = () => {
        if (currentSortIndex + 1 > sortTypes.length - 1) setSortIndex(0);
        else setSortIndex(currentSortIndex + 1);
        setSort(sortTypes[currentSortIndex]);
        fetchAllIbans();
    }

    /**
     * Submit new IBAN(s) to verification.
     * @param e - sort order String
     */
    const handleClick = (e: any) => {
        e.preventDefault();
        if (iban === "") {
            toast.info("Please enter IBAN")
            return;
        }

        let ibanArr = iban.split(',').map(s => ({iban: s.trim()})); // split comma separated values to IBAN object list
        const ibanObj = JSON.stringify(ibanArr);

        fetch(SERVER_URL + "/iban/validate?", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: ibanObj
        }).then(res => res.json())
            .then((results: any[]) => {
                fetchAllIbans();
                results.forEach(r => r.isValid === 0 ? toast.error("IBAN is not valid") : toast.success("IBAN is valid"));
            })
    };

    /**
     * Update (reactively) list of all saved IBAN addresses.
     */
    const fetchAllIbans = useCallback(() => {
        fetch(SERVER_URL + "/iban/getAll?" + new URLSearchParams({
            order: currentSort
        }))
            .then(res => res.json())
            .then(result => {
                setIbans(result);
            });
    }, [currentSort, setIbans]);

    /**
     * Execute fetch of new IBAN's.
     */
    useEffect(() => {
        fetchAllIbans();
        return () => {
            mountedRef.current = false;
        };
    }, [mountedRef, fetchAllIbans]);

    /**
     * Delete an IBAN entry.
     * @param id - Number representing IBAN object id
     */
    const handleDelete = (id: number) => {
        fetch(SERVER_URL + "/iban/remove/" + id, {method: "DELETE"})
            .then(() => {
                mountedRef.current = true; // allow update of IBAN list
                fetchAllIbans();
            });
    }

    return (
        <Container>
            <Paper elevation={3} style={paperStyle}>
                <h1 style={{color: "blue"}}>
                    <u>Validate IBAN</u>
                </h1>
                <h4>Add comma separated values for multiple input validation</h4>

                <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                        id="outlined-basic"
                        label="IBAN address"
                        variant="outlined"
                        fullWidth
                        value={iban}
                        onChange={e => setIban(e.target.value)}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                handleClick(event);
                            }
                        }}
                    />
                    <Button variant="contained" color="primary" onClick={handleClick}>
                        Submit
                    </Button>
                </form>
            </Paper>

            <h1 className={'ibans-list-header'}>All IBAN's</h1>

            <button onClick={onSortChange}>
                Order By: Validity
                <i className={`fas fa-${currentSort}`} style={{paddingLeft: "5px"}}/>
            </button>

            <Paper elevation={3} style={paperStyle}>
                {ibans.sort((a: any, b: any) => {
                    return a - b;
                })
                    .map((e: { [key: string]: any }) => (
                        <Paper
                            elevation={6}
                            style={{margin: "10px", padding: "15px", textAlign: "left"}}
                            key={e.id}
                        >
                            Id:{e.id}
                            <br/>
                            Address:{e.iban}
                            <br/>
                            IsValid: <span style={{color: e.isValid === 0 ? "red" : "green"}}>
                    {e.isValid === 0 ? "FALSE" : "TRUE"}</span>
                            <Button variant="contained" color="secondary" onClick={() => handleDelete(e.id)}
                                    style={{display: "flex"}}>
                                Delete
                            </Button>
                        </Paper>
                    ))}
            </Paper>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Container>
    );
}
